export const processes =
{
    0: "Autoclave & Oven",
    1: "Low Temperature & Infusion",
}

export const categories = {
    0: "everything",
    1: "Vacuum Bagging Films",
    2: "Release Films",
    3: "Breathers & Bleeders",
    4: "Peel Ply",
    5: "Sealant Tapes",
    6: "Pressure Sensitive Tapes",
    7: "Vacuum Valves & Hoses",
    8: "Resin Flow Mesh",
    9: "Infusion Tooling & Accessories",
    10: "Infusion Flow & Control Systems",
    11: "Combinators"
}

